/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-29",
    versionChannel: "nightly",
    buildDate: "2023-11-29T00:14:42.496Z",
    commitHash: "9e146c0f49d0067e4f29fd8c647bb3275b68c60f",
};
